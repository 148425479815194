import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
// import bank from "../../../assets/images/bank.png";
// import wallet from "../../../assets/images/wallet.png";
import flatt from "../../../assets/images/flatterwave.png";
import squad from "../../../assets/images/squad.png";
import { useAuth } from "../../../context/auth";
import useAxiosFunction from "../../../hooks/useAxiosFunction";
import { formatCurrency } from "../../../utils";
import InputField from "../../InputField/InputField";
import styles from "./styles.module.scss";

export type ProductNameTypes =
	| "Advance Payment"
	| "Average Debt Settlement Time"
	| "Backlog Settlement"
	| "Detailed Informal Report Of Transactions"
	| "Outstanding Salary Check"
	| "Trustworthiness Check";

export interface ProductsTypes {
	id: number;
	name: ProductNameTypes;
	price: number;
}

const paymentMethods = [
	// { id: "card", name: "Pay with card", icon: wallet },
	// { id: "bank", name: "Bank transfer", icon: bank },
	// { id: "flutterwave", name: "Flutterwave", icon: flatt },
	{ id: "paystack", name: "Paystack", icon: flatt },
	{ id: "squad", name: "Squad", icon: squad },
];

interface PaymentModalProps {
	onClose: () => void;
	productName?: ProductNameTypes;
}

const PaymentModal: React.FC<PaymentModalProps> = ({
	onClose,
	productName = "Advance Payment",
}) => {
	const { userData, refresh } = useAuth();
	const { axiosFetch } = useAxiosFunction();
	const modalRef = useRef<HTMLDivElement>(null);
	const [selectedMethod, setSelectedMethod] = useState<string>("squad");
	const [selectedProducts, setSelectedProducts] = useState<ProductsTypes>();
	const [createdBy] = useState(userData?.publicId);
	const [loading, setLoading] = useState(true);
	const [amount, setAmount] = useState<string>();

	const checkAmount = () => {
		if (productName !== "Advance Payment") return false;

		if (!amount || amount.length < 3) return true;
	};

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);

			const productRes = await axiosFetch({
				method: "GET",
				url: "/Products/GetProducts?pageNumber=1&pageSize=100",
			});

			const products: ProductsTypes[] = productRes?.productViewModel;

			if (products)
				setSelectedProducts(
					products.filter((val) => val.name === productName)[0]
				);

			setLoading(false);
		};

		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [productName]);

	useEffect(() => {
		const handleOutsideClick = (e: MouseEvent) => {
			if (modalRef.current && !modalRef.current.contains(e.target as Node)) {
				onClose();
			}
		};
		document.addEventListener("mousedown", handleOutsideClick);
		return () => document.removeEventListener("mousedown", handleOutsideClick);
	}, [onClose]);

	const handlePayment = async (channel: string) => {
		const paymentData = {
			amount:
				productName === "Advance Payment" ? amount : selectedProducts?.price,
			channel,
			paymentService: channel,
			productName,
			createdBy,
		};

		try {
			toast.promise(
				axiosFetch({
					method: "POST",
					url: `/Payments/CreatePayment`,
					requestConfig: {
						data: paymentData,
						headers: {
							Authorization: `Bearer ${userData?.token}`,
						},
					},
				}),
				{
					loading: "Creating payment...",
					success: (res) => {
						if (res.isSuccessful) {
							onClose();
							refresh();

							setTimeout(() => {
								VerifyPayment(res.channel, res.paymentReferenceId);
							}, 120000);

							if (res.checkoutUrl) window.open(res.checkoutUrl, "_blank");
							return res.remark || "Complete payment!";
						}
						throw new Error(
							res.message || "An error occurred, please try again!"
						);
					},
					error: (err) => err.message,
				}
			);
		} catch (error) {
			console.error("Error initiating Squad payment:", error);
		}
	};

	const VerifyPayment = async (channel: string, reference: string) => {
		try {
			toast.promise(
				axiosFetch({
					method: "GET",
					url: `/Payments/VerifyPayment?userId=${userData?.publicId}&paymentReferenceNumber=${reference}&channel=${channel}`,
					requestConfig: {
						headers: {
							Authorization: `Bearer ${userData?.token}`,
						},
					},
				}),
				{
					loading: "Verifying payment...",
					success: (res) => {
						if (res.isSuccessful) {
							return res.remark || "Verification completed!";
						}
						throw new Error(
							res.message || "An error occurred, please try again!"
						);
					},
					error: (err) => err.message,
				}
			);
		} catch (error) {
			console.error("Error verifying payment:", error);
		}
	};

	const renderPaymentMethods = () =>
		paymentMethods.map((method) => (
			<div
				key={method.id}
				className={`${styles.paymentMethod} ${
					selectedMethod === method.id ? styles.selected : ""
				}`}
				onClick={() => setSelectedMethod(method.id)}
			>
				<div className={styles.content}>
					<div className={styles.icon}>
						<img src={method.icon} alt={method.name} />
					</div>

					<span>{method.name}</span>
				</div>

				<input
					title="method"
					type="radio"
					checked={selectedMethod === method.id}
					onChange={() => setSelectedMethod(method.id)}
				/>
			</div>
		));

	return (
		<div className={styles.overlay}>
			<div className={styles.modal} ref={modalRef}>
				<h6>Make Payment</h6>

				<p className={styles.sub}>
					Kindly make payment to continue this process.
				</p>

				{loading ? (
					<Spinner />
				) : (
					<>
						<div className={styles.paymentOptions}>
							{renderPaymentMethods()}
						</div>

						{selectedProducts?.name === "Advance Payment" ? (
							<div style={{ marginTop: "25px" }}>
								<InputField
									type="number"
									label="Enter an amount"
									name="amountInvolved"
									placeholder="Enter amount here"
									value={amount || 0}
									onChange={(_name, e) => {
										setAmount(Number(e.target.value.trim()).toFixed());
									}}
									error={
										checkAmount() && amount
											? "Enter an amount above ₦100"
											: undefined
									}
									isRequired
								/>
							</div>
						) : (
							<div className={styles.details}>
								<span>1 Request</span>

								<span>{formatCurrency(selectedProducts?.price || 0)}</span>
							</div>
						)}

						<button
							className="btn"
							onClick={() => handlePayment(selectedMethod)}
							disabled={checkAmount()}
						>
							Proceed with {selectedMethod}
						</button>
					</>
				)}
			</div>
		</div>
	);
};

export default PaymentModal;

export const Spinner = () => (
	<div className={styles.spinner}>
		<svg
			width="50"
			height="50"
			viewBox="0 0 50 50"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle
				cx="25"
				cy="25"
				r="20"
				strokeWidth="5"
				fill="none"
				strokeDasharray="100"
				strokeDashoffset="0"
			/>
		</svg>
	</div>
);
