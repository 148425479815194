import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useAuth } from "../../../context/auth";
import useAxiosFunction from "../../../hooks/useAxiosFunction";
import { formatCurrency, formatDate } from "../../../utils";
import Pagination from "../../Table/Pagination";
import StatusIndicator from "../../Table/StatusIndicator";
import styles from "./styles.module.scss";

type WalletViewModel = {
	publicId: string;
	channel: string;
	amount: number;
	paymentService: string;
	productName: string;
	userPublicId: string;
	paymentReferenceId: string;
	date: Date;
	isConfirmed: boolean;
	currency: string;
	isSuccessful: boolean;

	// publicId: string;
	// userId: string;
	// balance: number;
	// businessName: string | null;
	// identificationId: string | null;
	// lifeTimeCostIncurred: number;
	// earnedReferralBonus: number;
	// isIndividual: boolean;
	// isStaff: boolean | null;
	// isConfirmedStaff: boolean | null;
	// initialOutstandingDate: string | null;
	// name: string | null;
	// mostRecentTransactionType: string | null;
	// mostRecentPaymentService: string | null;
	// mostRecentTransactionAmount: number;
	// nameOfLastTransactionBeneficiary: string | null;
};

const PaymentTable = ({
	setTotalTransaction,
}: {
	setTotalTransaction: (val: number) => void;
}) => {
	const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
	const { userData, refreshCount } = useAuth();
	const { axiosFetch } = useAxiosFunction();
	const [transaction, setTransactions] = useState<WalletViewModel[]>([]);
	const [totalPages, setTotalPage] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);

	useEffect(() => {
		const fetchData = async () => {
			const trans = await axiosFetch({
				method: "GET",
				// url: `/Wallets/GetAllWallets?pageNumber=${currentPage}&pageSize=10&userId=${userData?.publicId}`,
				url: `/Payments/GetPayments?userId=${userData?.publicId}&pageNumber=${currentPage}&pageSize=10`,
				requestConfig: {
					headers: {
						Authorization: `Bearer ${userData?.token}`,
					},
				},
			});

			setTransactions(trans?.paymentViewModel || []);
			setTotalTransaction(trans?.totalCount || 0);
			setTotalPage(Math.ceil(trans?.totalCount));
		};

		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPage, userData, refreshCount]);

	const handlePageChange = (page: number) => {
		setCurrentPage(page);
	};

	const VerifyPayment = async (channel: string, reference: string) => {
		try {
			toast.promise(
				axiosFetch({
					method: "GET",
					url: `/Payments/VerifyPayment?userId=${userData?.publicId}&paymentReferenceNumber=${reference}&channel=${channel}`,
					requestConfig: {
						headers: {
							Authorization: `Bearer ${userData?.token}`,
						},
					},
				}),
				{
					loading: "Verifying payment...",
					success: (res) => {
						if (res.isSuccessful) {
							return res.remark || "Verification completed!";
						}
						throw new Error(
							res.message || "An error occurred, please try again!"
						);
					},
					error: (err) => err.message,
				}
			);
		} catch (error) {
			console.error("Error verifying payment:", error);
		}
	};

	const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			setSelectedUsers(transaction.map((trans) => trans.publicId));
		} else {
			setSelectedUsers([]);
		}
	};

	const handleSelectUser = (userId: string) => {
		setSelectedUsers((prevSelected) =>
			prevSelected.includes(userId)
				? prevSelected.filter((id) => id !== userId)
				: [...prevSelected, userId]
		);
	};

	return (
		<div className={styles.table}>
			<div className={styles.tableContainer}>
				<table>
					<thead>
						<tr>
							<th align="center">
								<input
									title="selectedUsers"
									type="checkbox"
									checked={selectedUsers.length === transaction.length}
									onChange={handleSelectAll}
								/>
							</th>

							<th align="left">Product Name</th>

							<th align="left">Amount</th>

							<th align="left">Channel</th>

							<th align="left">Date</th>

							<th align="left">Status</th>
						</tr>
					</thead>
					<tbody>
						{transaction.map((trans) => (
							<tr key={trans.publicId}>
								<td align="center">
									<input
										type="checkbox"
										title="publicId"
										checked={selectedUsers.includes(trans.publicId)}
										onChange={() => handleSelectUser(trans.publicId)}
									/>
								</td>

								<td align="left" className={styles.user}>
									{trans.productName}
								</td>

								<td align="left">{formatCurrency(trans.amount)}</td>

								<td align="left">{trans.channel}</td>

								<td align="left">{trans.date && formatDate(trans.date)}</td>

								<td align="left">
									{trans.isConfirmed ? (
										<StatusIndicator
											color={trans.isConfirmed ? "green" : "red"}
											status={trans.isConfirmed ? "Verified" : "Not Verified"}
										/>
									) : (
										<button
											type="button"
											className="btn"
											style={{
												padding: "8px 8px",
												height: "auto",
												fontSize: "10px",
											}}
											onClick={() =>
												VerifyPayment(trans.channel, trans.paymentReferenceId)
											}
										>
											Verify Transaction
										</button>
									)}
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>

			{totalPages > 10 && (
				<Pagination
					total={totalPages / 10}
					current={currentPage}
					onPageChange={handlePageChange}
				/>
			)}
		</div>
	);
};

export default PaymentTable;
