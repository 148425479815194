import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import { ResType } from "../components/Dashboard/Payment/chartData";
dayjs.extend(isoWeek);

export const cx = (...classNames: string[]) =>
	classNames.filter(Boolean).join(" ");

export const slugify = (str: string): string => {
	return str
		.toLowerCase()
		.trim()
		.replace(/[^\w\s-]/g, "")
		.replace(/\s+/g, "-")
		.replace(/--+/g, "-");
};

export const formatToMoney = (num: number) => {
	return num
		.toLocaleString("en-US", {
			style: "currency",
			currency: "NGN",
			minimumFractionDigits: 0,
			maximumFractionDigits: 2,
		})
		.replace("NGN", "₦");
};

export const validatePassword = (password: string): string => {
	const minLength = 8;
	const hasUpperCase = /[A-Z]/.test(password);
	const hasLowerCase = /[a-z]/.test(password);
	const hasNumber = /\d/.test(password);
	const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

	if (password.length < minLength)
		return "Password must be at least 8 characters long.";
	if (!hasUpperCase)
		return "Password must contain at least one uppercase letter.";
	if (!hasLowerCase)
		return "Password must contain at least one lowercase letter.";
	if (!hasNumber) return "Password must contain at least one number.";
	if (!hasSpecialChar)
		return "Password must contain at least one special character.";

	return "";
};

export const formatDate = (dateString: string | Date): string => {
	const options: Intl.DateTimeFormatOptions = {
		year: "numeric",
		month: "short",
		day: "numeric",
	};
	const date = new Date(dateString);
	return date.toLocaleDateString("en-US", options); // "Oct 24, 2024"
};

export const formatCurrency = (amount: number): string => {
	return new Intl.NumberFormat("en-NG", {
		style: "currency",
		currency: "NGN",
	}).format(amount);
};

export const groupDataByPeriod = (
	metrics: {
		addedRecords: ResType[];
		searchedRecords: ResType[];
		funded: ResType[];
		// charges: ResType[];
	},
	period: "daily" | "weekly" | "monthly"
) => {
	const groupBy = (
		data: ResType[],
		formatter: (date: dayjs.Dayjs) => string
	) => {
		const grouped = data.reduce((acc, item) => {
			const date = dayjs(item.timestamp);
			const key = formatter(date);
			if (!acc[key]) acc[key] = 0;
			acc[key] += item.data;
			return acc;
		}, {} as Record<string, number>);

		const categories = Object.keys(grouped).sort();
		const values = categories.map((key) => grouped[key]);
		return { categories, values };
	};

	const formatters = {
		daily: (date: dayjs.Dayjs) => date.format("YYYY-MM-DD"),
		weekly: (date: dayjs.Dayjs) => `${date.isoWeekYear()}-W${date.isoWeek()}`,
		monthly: (date: dayjs.Dayjs) => date.format("YYYY-MM"),
	};

	const added = groupBy(metrics.addedRecords, formatters[period]);
	const searched = groupBy(metrics.searchedRecords, formatters[period]);
	const funded = groupBy(metrics.funded, formatters[period]);
	// const charges = groupBy(metrics.charges, formatters[period]);

	const allCategories = Array.from(
		new Set([
			...added.categories,
			...searched.categories,
			...funded.categories,
			// ...charges.categories,
		])
	).sort();

	const alignValues = (
		categories: string[],
		groupedData: { categories: string[]; values: number[] }
	) =>
		categories.map((cat) => {
			const index = groupedData.categories.indexOf(cat);
			return index >= 0 ? groupedData.values[index] : 0;
		});

	return {
		categories: allCategories,
		series: [
			{
				name: "Added Records",
				data: alignValues(allCategories, added),
			},
			{
				name: "Searched Records",
				data: alignValues(allCategories, searched),
			},
			{
				name: "Funded",
				data: alignValues(allCategories, funded),
			},
			// {
			// 	name: "Charges",
			// 	data: alignValues(allCategories, charges),
			// },
		],
	};
};

export default {
	cx,
	slugify,
	validatePassword,
	formatToMoney,
	formatDate,
	formatCurrency,
	groupDataByPeriod,
};
