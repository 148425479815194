import { ApexOptions } from "apexcharts";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useAuth } from "../../../context/auth";
import { groupDataByPeriod } from "../../../utils";
import { fetchMetrics } from "./chartData";
import styles from "./chartsStyles.module.scss";
import DonutChart from "./DonutChart";

export interface ResType {
	timestamp: string;
	data: number;
}

const Chart = () => {
	const { userData, refreshCount } = useAuth();
	const [rawData, setRawData] = useState<{
		categories: string[];
		series: {
			name: string;
			data: number[];
		}[];
	}>();
	const [starts, setStarts] = useState<
		{
			name: string;
			total: number;
			percentage: string;
		}[]
	>();
	const [metrics, setMetrics] = useState<{
		addedRecords: ResType[];
		searchedRecords: ResType[];
		funded: ResType[];
		// charges: ResType[];
	}>();
	const [selectedFilter, setSelectedFilter] = useState<
		"daily" | "weekly" | "monthly"
	>("weekly");

	useEffect(() => {
		const fetchDate = async () => {
			const res = await fetchMetrics(userData);

			setMetrics(res);
		};

		fetchDate();
	}, [userData, refreshCount]);

	useEffect(() => {
		if (!metrics) return;

		const data = groupDataByPeriod(metrics, selectedFilter);

		const totalSum = data.series.reduce((total, record) => {
			return total + record.data.reduce((sum, value) => sum + value, 0);
		}, 0);

		const results = data.series.map((record) => {
			const recordSum = record.data.reduce((sum, value) => sum + value, 0);
			const percentage = (recordSum / totalSum) * 100;
			return {
				name: record.name,
				total: recordSum,
				percentage: percentage.toFixed(2),
			};
		});

		setStarts(results);
		setRawData(data);
	}, [selectedFilter, metrics]);

	const chartOptions: ApexOptions = {
		chart: {
			id: "basic-bar",
		},
		xaxis: {
			categories: rawData?.categories,
		},
		colors: ["#032B6B", "#0866FF", "#B2D0FF", "#FFA108"],
		stroke: {
			curve: "smooth",
		},
		plotOptions: {
			bar: {
				columnWidth: "60%",
			},
		},
		dataLabels: {
			enabled: false,
		},
		legend: {
			fontSize: "8px",
			fontWeight: 400,
		},
	};

	const handleFilterChange = (filter: "daily" | "weekly" | "monthly") => {
		setSelectedFilter(filter);
	};

	return (
		<div className={styles.chartContainer}>
			<div className={styles.contents}>
				<div className={styles.barChatContainer}>
					<div className={styles.chartHeader}>
						<div>
							<p>Statistics</p>

							<h3>Total summary</h3>
						</div>

						<div className={styles.filterButtons}>
							<button
								className={selectedFilter === "daily" ? styles.active : ""}
								onClick={() => handleFilterChange("daily")}
							>
								Daily
							</button>
							<button
								className={selectedFilter === "weekly" ? styles.active : ""}
								onClick={() => handleFilterChange("weekly")}
							>
								Weekly
							</button>
							<button
								className={selectedFilter === "monthly" ? styles.active : ""}
								onClick={() => handleFilterChange("monthly")}
							>
								Monthly
							</button>
						</div>
					</div>

					<div className={styles.chart}>
						{rawData && (
							<ReactApexChart
								options={chartOptions}
								series={rawData.series}
								type="bar"
								height={350}
							/>
						)}
					</div>
				</div>

				<div className={styles.indicators}>
					<div className={styles.indicatorHeader}>
						<h4>Indicators</h4>
					</div>

					<div className={styles.indicatorContent}>
						<ul>
							{starts?.map((start, i) => (
								<li key={i}>
									<span
										style={{
											backgroundColor:
												(start.name === "Added Records" && "#032B6B") ||
												(start.name === "Searched Record" && "#0866FF") ||
												(start.name === "Funded" && "#B2D0FF") ||
												"#FFA108",
										}}
									/>
									{start.name} - {start.total}
								</li>
							))}
						</ul>
					</div>
				</div>
			</div>

			<DonutChart filter={selectedFilter} starts={starts || []} />
		</div>
	);
};

export default Chart;
