import { FC, useEffect, useState } from "react";
import { SpentSvg, TransactionSvg } from "../../../assets/svg/StartsSvg";
import { useAuth } from "../../../context/auth";
import useAxiosFunction from "../../../hooks/useAxiosFunction";
import { formatToMoney } from "../../../utils";
import Charts from "./Charts";
import PaymentTable from "./PaymentTable";
import PaymentWallet from "./PaymentWallet";
import styles from "./styles.module.scss";

interface PaymentProps {}

const Payment: FC<PaymentProps> = (): JSX.Element => {
	const { userData, setUserData } = useAuth();
	const { axiosFetch } = useAxiosFunction();
	const [balance, setBalance] = useState(0);
	const [amountSpent, setAmountSpent] = useState(0);
	const [totalTransaction, setTotalTransaction] = useState(0);

	const fetchData = async () => {
		const userBalance = await axiosFetch({
			method: "GET",
			url: `/Wallets/GetUserWalletBalance?userId=${userData?.publicId}`,
			requestConfig: {
				headers: {
					Authorization: `Bearer ${userData?.token}`,
				},
			},
		});

		const trans = await axiosFetch({
			method: "GET",
			// url: `/Wallets/GetAllWallets?pageNumber=${currentPage}&pageSize=10&userId=${userData?.publicId}`,
			// url: `/Payments/GetPayments?userId=${userData?.publicId}&pageNumber=1&pageSize=100`,
			url: `/PaidApiCalls/GetPaidApiCallCostForUserId?userId=${userData?.publicId}`,
			requestConfig: {
				headers: {
					Authorization: `Bearer ${userData?.token}`,
				},
			},
		});

		if (trans.isSuccessful) {
			setAmountSpent(trans.totalNairaCost);
		}

		if (userBalance.isSuccessful) {
			setBalance(userBalance.balance);
			setUserData({ balance: userBalance.balance });
		}
	};

	useEffect(() => {
		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={styles.payment}>
			<div className={styles.walletContainer}>
				<PaymentWallet balance={balance} fetchData={fetchData} />

				<div className={styles.start}>
					<div className={styles.left}>
						<span>
							<p>Transactions made</p>
						</span>

						<span className={styles.content}>
							<span className={styles.svg}>
								<TransactionSvg />
							</span>

							<p>
								<span>{totalTransaction}</span>
								<span>Transactions</span>
							</p>
						</span>
					</div>

					<div className={styles.right}>
						<span>
							<p>Amount spent</p>
						</span>

						<span className={styles.content}>
							<span className={styles.svg}>
								<SpentSvg />
							</span>

							<p>
								<span>{formatToMoney(amountSpent)}</span>
								<span>Naira</span>
							</p>
						</span>
					</div>
				</div>
			</div>

			<Charts />

			<PaymentTable setTotalTransaction={setTotalTransaction} />
		</div>
	);
};

export default Payment;
